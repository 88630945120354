(() => {
  let accordionItems;
  function initAccordion(module) {

    accordionItems = module.querySelectorAll('.js-accordion-item');

    accordionItems.forEach(item => {

      item.addEventListener('click', (event) => {
        if(item.classList.contains('accordion-element--is-open')) {
          item.classList.remove('accordion-element--is-open');
        } else {
          item.classList.add('accordion-element--is-open');
        }

        event.preventDefault();
      });
    });

    module.classList.add('js-enhanced');
  }

  const accordionModules = document.querySelectorAll('.js-accordion:not(.js-enhanced)');
  accordionModules.forEach(initAccordion);
})();
